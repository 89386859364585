.contact-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}


.button-row {
    display: flex;
   
    position: relative;
    justify-content: space-around;
    gap: 1rem;

    /* Adjust spacing between content and buttons */
}

.bitmoji-center-contact {
    z-index: 10;
    pointer-events: none;
    width: 500px;
    height: auto;
    position: absolute;
    /* Ensures that the element's position is relative to its parent container */
    animation: float 30s ease-in-out infinite;
}



.button-title {
    font-family: 'Inter Bold';
    font-size: 12px;
    margin-left: 1rem;
}

.arrow-image-contact {
    width: 30px;
    margin-top: 5px;
    margin-right: 5rem;
    transition: transform 0.3s ease;
}



.contact-button {
    font-family: 'Inter Bold';
    position: relative;
    overflow: hidden;
    color: white;
    
    background-color: transparent;
    border: 1px solid white;
    border-radius: 10rem;
    padding: 30px 50px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.6s ease, transform 0.3s ease;
    display: flex;
    align-items: center;

    justify-content: space-between;
    /* Center contents horizontally */
    gap: 5px;

   
}

.contact-button .fill {
    position: absolute;
    top: 0;
    left: 0;
   
    background-color: var(--red);
    /* Or any color you want for the fill effect */
    transform: scaleX(0);
    transform-origin: left;
    z-index: 0;
    transition: transform 0.6s ease, background-color 0.6s ease;
}

.contact-button svg {
    position: relative;
    z-index: 1;
    transition: fill 0.3s ease;
}

.button-text {
    position: absolute;

    opacity: 0;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 1;
}

.contact-button:hover {
    background-color: var(--red);
    transform: scale(1.09);


}

.contact-button:hover .arrow-image-contact {
    animation: wiggle 0.6s ease-in-out infinite;
    /* Apply wiggle animation on hover */
}



.contact-button:hover .button-text {
    opacity: 1;
    transform: translate(-50%, 0);
    /* Adjust to bring text up */
}

.copy-message {
    font-family: 'Inter Bold';
    position: absolute;
    top: -30px; /* Adjust as needed */
    left: 90px;
    transform: translateX(-50%);
    
    color: white; /* Text color */
    padding: 5px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
  }
  


@keyframes float {
    0% {
        transform: translate(0, 0);
    }

    10% {
        transform: translate(5px, -10px);
    }

    20% {
        transform: translate(-5px, 10px);
    }

    30% {
        transform: translate(15px, -5px);
    }

    40% {
        transform: translate(-10px, 10px);
    }

    50% {
        transform: translate(5px, 5px);
    }

    60% {
        transform: translate(-15px, 0px);
    }

    70% {
        transform: translate(10px, -10px);
    }

    80% {
        transform: translate(-5px, 15px);
    }

    90% {
        transform: translate(0px, -5px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes wiggle2 {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(-5deg);
    }

    50% {
        transform: rotate(5deg);
    }

    75% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}


@media (max-width: 768px) {
    
    .contact-page {
        flex-direction: column;
        /* Stack elements vertically */
        justify-content: flex-start;
        align-items: center;
        justify-content: center;
        /* Align elements to the top */
        height: 100vh;
        /* Adjust height to fit content */
       
        /* Add padding for better spacing */
        overflow: visible;
        /* Allow content to be fully visible */
    }

    .button-row {
        flex-direction: column;
        /* Stack buttons vertically */
       
        /* Maintain gap between buttons */
        width: 100%;
        /* Make button row full width */
        justify-content: center;
        /* Center buttons */
        align-items: center;
    }

    .bitmoji-center-contact {
        width: 300px;
        /* Reduce image size for mobile */
        left: auto;
        right: auto;
        margin: 0 auto;
        /* Center the bitmoji on mobile */
    }

    .contact-button {
        padding: 20px 30px;
        /* Adjust padding for smaller screens */
        font-size: 10px;
        /* Adjust font size for buttons */
        width: 80vw;
        /* Make buttons full width */
        justify-content: space-between;
        /* Center the button content */
       
    }

    .arrow-image-contact {
        width: 20px;
        margin-right: 0;
        /* Remove excessive margin on smaller screens */
    }

    .button-title {
        font-size: 10px;
        margin-left: 0.5rem;
        /* Adjust font size and margin for smaller screens */
    }

    .icon-container {
        justify-content: center;
        /* Center icons */
        width: 100%;
        /* Adjust width for better spacing */
    }

    .icon {
        width: 25px;
        /* Adjust icon size for smaller screens */
        height: 25px;
    }

    .button-text {
        font-size: 10px;
        /* Adjust text size for smaller screens */
    }
}
