/* Container for the loader */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}

/* Slider container for the sliding image */
.slider-container {
  position: absolute;
  bottom: 0px; /* Position at the bottom */
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* Image that slides in from the bottom left and goes to the right */
.slider-image {
  width: 500px;
  height: 500px;
  background-image: url('/public/images/peek.png'); /* Replace with your image path */
  background-size: cover;
  position: absolute;
  bottom: -100px; /* Start below the container */
  left: -100px; /* Start off the left side */
  animation: slideIn 8s forwards;
}

/* Keyframes for sliding animation */
@keyframes slideIn {
  0% {
    bottom: 0px; /* Start from below the container */
    left: -500px; /* Start from the left of the container */
  }
  50% {
    bottom: 0px; /* Slightly inside the container */
  }
  100% {
    bottom: 0px; /* Remain inside the container */
    left: 120%; /* Slide out to the right side */
  }
}

/* Loading bar container */
.loading-bar {
 
  width: 80%;
  height: 20px;
  background-color: white;
  border-radius: 10px;
  margin: 20px 0;
  position: relative;
}

/* Progress bar */
.progress-bar {
 
  height: 100%;
  background-color: var(--red);
  border-radius: 10px;
  transition: width 0.3s;
}

/* Loading text */
.loading-text {
  color: white;
  font-family: 'Inter Bold', sans-serif;
  font-size: 2rem;
}


@media (max-width: 768px) {
  .loading-text {
    color: white;
    font-family: 'Inter Bold', sans-serif;
    font-size: 1rem;
  }

  .slider-image {
    width: 150px;
    height: 150px;
    
  }

  @keyframes slideIn {
    0% {
      bottom: 0px; /* Start from below the container */
      left: -150px; /* Start from the left of the container */
    }
    50% {
      bottom: 0px; /* Slightly inside the container */
    }
    100% {
      bottom: 0px; /* Remain inside the container */
      left: 120%; /* Slide out to the right side */
    }
  }
}