.macbook-model {
  position: fixed;
  top: 0;
  left: 0;
  
  
}
/* MacbookModel.css */

@keyframes slideInMac {
    from {
      transform: translateX(-100vw); /* Start off-screen to the left */
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .model-container {
    animation: slideInMac 1s ease-out; /* Adjust duration and easing as needed */
  }
  
@media (max-width: 768px) {
    .macbook-model {
        top: 35%;
        position: absolute;
        z-index: 10000;
        width: 100vw;
        height: 200vh;
        scale: 1;
       
    }
}