:root {
  --red: #739ab9;       
  --black: #B02A29;     
  --gray: #69120B;      
  --white: #FFFFFF;     
  --light-gray: hwb(236 32% 8%); 
  --light-brown: #FBF0D8; 
  --color1: hsl(31, 100%, 48%);     
}




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
