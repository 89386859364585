.music-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* text-align: center; */
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
.all-music-images{
    display: flex;
}

.music-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
}

.column-wrapper-left,
.column-wrapper-right {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;

    position: relative;
}

.center-image-wrapper {
    margin: 0 0rem;
}

.bitmoji-center {
    position: relative;
    width: 370px;
    right: 8rem;
    height: auto;
}

.image-link-container {
    border-radius: 16px;

    transform-style: preserve-3d;
    position: relative;
    display: inline-block;
    /* overflow: hidden; */
    transform-style: preserve-3d;
    transition: transform 0.2s ease-out;
}

.image-link-container.hovered {
    transform: rotateX(0) rotateY(0) translateY(0) !important;
}

.music-image-left,
.music-image-right {

    border-radius: 16px;
    width: 10em;
    height: auto;
    display: block;
    box-sizing: border-box;
    transition: filter 0.3s ease-in-out;
}

.image-link-container:hover .music-image-left,
.image-link-container:hover .music-image-right {
    filter: brightness(0.8);
}

.overlay {

    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.6); */
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease-in-out;
}

.image-link-container:hover .overlay {
    opacity: 1;
}

.icon-container {
    display: flex;
    justify-content: space-between;
    width: 100px;
}

.icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.icon:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .music-page {
      flex-direction: column;
      height: 100vh;
      overflow: hidden;
      align-items: center;
    }

    .all-music-images{
        display: flex;
        flex-direction: column;
       
    }
  
    .music-page-container {
      display: flex;
    
      height: auto;
      padding: 20px 0;
    }
  
    .column-wrapper-left,
    .column-wrapper-right {
      flex-direction: column;
      justify-content: center;
      height: auto;
      width: 100%;
      margin: 0 0;
    }
  
    .column-wrapper-left {
      order: 1; /* Ensure left column is on top */
    }
  
    .center-image-wrapper {
      margin: 0 auto;
    }
  
    .bitmoji-center {
     
      z-index: 1;
      width: 200px;
      left: 0rem;
      margin: 0 auto;
    }
  
    
  
    .image-link-container {
      width: 100%;
      margin: 5px 0;
    }
  
    .music-image-left,
    .music-image-right {
      width: 8em;
    }
  
    .overlay {
      top: 5px;
    }
  
    .icon-container {
      justify-content: space-around;
      width: 80px;
    }
  
    .icon {
      width: 30px;
      height: 30px;
    }
  }