
@font-face {
    font-family: 'Inter Bold';
    src: url('/public/fonts/Inter/Inter_18pt-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter Italic';
    src: url('/public/fonts/Inter/Inter_18pt-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Inter Regular';
    src: url('/public/fonts/Inter/Inter_24pt-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }



  @font-face {
    font-family: 'Broman';
    src: url('/public/fonts/broman.ttf') format('truetype');
    font-weight: 600;
  }