.gallery-container {
    position: relative;
    width: 100vw;
    height: 100vh;
   
    overflow: hidden;
   
  }

  .graphics-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
    flex-wrap: wrap; /* Allow images to wrap to the next line */
    justify-content: center; /* Center images horizontally */
  }
  
  .gallery-viewport {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .gallery-plane {
    
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none; /* Allow pointer events to pass through */
  
  }

  .image-grid-graphics {
    
    padding-right: 10%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: flex-start;
    margin-left: 0;
  }
  
  .gallery-plane img {
 
    position: absolute; /* Position images absolutely within the plane */
    width: 400px; /* Adjust as needed */
    height: auto;
    margin: 5px;
    border-radius: 10px;
    transition: transform 0.3s ease, filter 0.3s ease;
  }
  
  .gallery-plane img:hover {
    filter: grayscale(0%);
    transform: scale(1.1);
  }
  