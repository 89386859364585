.buffer-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  
  .images-container{
    display: flex;
  }
 
  .bitmoji-images{
    width: 400px;
  }