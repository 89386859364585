/* Keyframe animations */
@keyframes textLoad {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInImage {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-family: 'Inter Bold';
  animation: slideIn 0.5s ease-out;
}

.navbar-left,
.navbar-right {
  flex: 1;
  display: flex;
}

.navbar-center {
  flex: 2;
  display: flex;
  justify-content: center;
}

.navbar-left {
  justify-content: flex-start;
  font-family: 'Inter Regular';
}

.navbar-right {
  justify-content: flex-end;
  font-family: 'Inter Regular';
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 80px;
}

.navbar ul li {
  position: relative;
  transition: transform 0.3s ease;
}

.navbar ul li a {
  color: var(--light-brown);
  text-decoration: none;
  font-size: 12px;
  transition: color 0.3s ease;
}

.navbar ul li a:hover {
  color: var(--red);
}

/* Home container styles */
.home-container {
  width: 100vw;
  height: 100vh;
  color: var(--light-brown);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0;
  position: relative;
}

/* Super text styles */
.super-text {
  font-family: 'Broman';
  color: var(--light-brown);
  font-size: 19vw;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  text-align: start;
  white-space: nowrap;
  animation: textLoad 1s ease-out;
  padding: 20px;
  transform: translateY(0);
  opacity: 1;
}

/* Bitmoji image styles */
.bitmoji-image {
  position: absolute;
  bottom: 0;
  right: -60px;
  width: 700px;
  height: auto;
  z-index: 100;
  animation: slideInImage 1s ease-out;
  transform: translateX(0);
  opacity: 1;
}

/* Steve text styles */
.steve {
  font-family: 'Inter Bold';
}

.super,
.steve {
  display: block;
  margin: 0;
  line-height: 1;
}

.super.reveal,
.steve.reveal {
  opacity: 1;
  transform: translateY(0);
}

/* Media queries */
@media (max-width: 768px) {

  .navbar-left {
    justify-content: center;
    font-family: 'Inter Regular';
  }

  .navbar-right {
    justify-content: center;
    font-family: 'Inter Regular';
  }

  .navbar ul {
    align-items: center;
    gap: 20px;

  }



  .bitmoji-image {
    position: absolute;
    bottom: 0;
    right: -30px;
    width: 300px;
    bottom: 5rem;
    height: auto;
    z-index: 100;
    animation: slideInImage 1s ease-out;
    transform: translateX(0);
    opacity: 1;
  }

  .home-container {
    height: 100vh;
  }

  .navbar ul li a {

    font-size: 12px;

  }

  .super-text {
    font-size: 18vw;
    bottom: 4rem;
    width: 100vw;

  }


}