html, body {
  max-width: 100%;
  overflow-x: hidden;
}

[data-scroll-container] {
  scroll-snap-type: y mandatory; /* Enable scroll snapping vertically */
}

[data-scroll-section] {
  scroll-snap-align: start; /* Align the start of each section */
}

::-webkit-scrollbar {
  display: none;
}

.c-scrollbar_thumb {

  opacity: 0;
  

}
div[animate] {
  display: inline-block; /* Ensure the element is visible */
}

.scroll-container-main {
  position: relative;
  overflow: hidden;
  background-color: transparent;
}

.reveal-text {
  margin: 0 auto;
  padding: 50px;
  text-align: center;
  font-size: 2rem;
}

.animate {
 
  flex-wrap: wrap; /* Allow text to wrap */
  justify-content: right; /* Center text horizontally */
  text-align: right; /* Center text within each line */
  display: inline-block; /* Ensure the element is visible */
}

.animate .word {
  display: inline-block;
  opacity: 1;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 9999;
}

#scroll-left {
  left: 10px;
}

#scroll-right {
  right: 10px;
}

.sticker-image{
 height: 50rem;
 width: 50rem;
 align-self: center;
}

.background-image-one, .background-image-two {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1; /* Base layer */
}




body {
  overflow: auto;
  height: 100%;
 
}

.section{
  height: 100vh;
  width: 100vw;
}


.App {
  text-align: center;
  display: flex;
  width: 100vw;
  flex-direction: column;
}

.home-page{
  background-color: var(--black);
  width: 100vw;
  height: 100vh;
}

.works-container{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.works-container {
  position: relative;
  overflow: hidden; /* Hide overflow to prevent vertical scrolling */
}

.works-page {
  display: flex; /* Arrange items horizontally */
  flex-direction: row; /* Horizontal arrangement */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
  scroll-snap-type: x mandatory; /* Snap to each video */
  width: 100vw; /* Ensure full width */
  height: 100vh; /* Ensure full height */
}


.video-container {
  flex: 0 0 100vw; /* Each video takes full viewport width */
  height: 100%; /* Ensure full height */
  scroll-snap-align: center; /* Snap to the center of each video */
}