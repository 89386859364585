/* InfiniteScrollText.css */

.scroll-container {
    font-family: 'Inter Regular';
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 20px;
    bottom: 0px;
    width: 100vw;
    /* Full viewport width */
}

.scroll-container2 {
    font-family: 'Inter Regular';
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 20px;
    top: 0px;
    width: 100vw;
    /* Full viewport width */
}

.scroll-content {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.scroll-text {
    color: white;
    font-size: 24px;
    padding: 0 20px;
}

.arrow-icon-skills {
    align-items: center;
    width: 18px; /* Set the width of the arrow image */
    height: 18px; /* Set the height of the arrow image */
    vertical-align: middle; /* Center the arrow image vertically */
    margin-right:  20px;
    margin-left: 10px;
    margin-bottom: 3px;
  }

  @media only screen and (max-width: 768px) {
    .scroll-container {
        position: relative;
        bottom: -100px;
    
    }
    
    .scroll-text {
        color: white;
        font-size: 16px;
        padding: 0 20px;
    }

    .arrow-icon-skills {
        align-items: center;
        width: 14px; /* Set the width of the arrow image */
        height: 14px; /* Set the height of the arrow image */
        vertical-align: middle; /* Center the arrow image vertically */
        margin-right:  20px;
        margin-left: 10px;
        margin-bottom: 3px;
      }

  }