@keyframes blobLoad {
    0% {
        transform: scale(0.5);
        /* Start small */
        opacity: 0;
    }

    50% {
        transform: scale(1.1);
        /* Scale up slightly */
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        /* End at original size */
        opacity: 1;
    }
}

.blob {
    position: relative;

    width: 500px;
    height: 500px;
    background: radial-gradient(circle, var(--red) 30%, #282828 100%);
    filter: blur(30px);
    pointer-events: none;
    transition: left 0.3s ease-out, top 0.3s ease-out;
    /* Smooth transitions for position */
    z-index: 9999;
    border-radius: 50%;
    /* Ensuring it starts as a circle */
    /* Apply the keyframe animation on initial load */
    transition: border-radius 0.2s ease-out, transform 0.2s ease-out;
    /* Smooth transition for shape and position changes */
    animation: blobLoad 1s ease-out;
}

@media (max-width: 768px) {
    .blob {
        width: 400px;
        height: 400px;
        /* position: fixed; */
    }
}