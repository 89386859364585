.about-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}


.bitmoji-image-left {
    pointer-events: none;
    position: absolute;
    left: 0; /* Align to the left edge */
    bottom: 15%; /* Align to the bottom */
    width: 600px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    z-index: 5; /* Ensure image is below text but above other elements */
    animation: fadeInImage 1s ease-out; /* Apply opacity animation if needed */
  }

.small-heading {
    color: var(--gray);
    opacity: 0.3;
    font-family: 'Broman';
    text-decoration: none;
    position: absolute;
    font-size: 22rem;
    /* left: 20%; */
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
   
    justify-content: center; /* Center heading horizontally */
    z-index: -100;
    text-align: center; /* Align text to the left */
}

.about-me-text-container {
    display: flex; /* Flexbox for centering content */
    flex-direction: column; /* Arrange items vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    font-family: "Inter Bold";
    font-size: 2rem;
    width: 65%; /* Adjust width as needed */
    color: var(--light-brown); 
}

.about-me-text {
   
    display: flex; /* Ensure proper spacing and alignment */
    flex-wrap: wrap; /* Allow text to wrap */
    gap: 5px; /* Space between words */
}


.word {
    display: inline-block;
    opacity: 0;
    
    /* Initially hidden */
    transform: translateY(20px);
    /* Start slightly lower */
}


/* Mobile CSS for About Page */

@media only screen and (max-width: 768px) {
    .about-page {
       
      flex-direction: column; /* Stack elements vertically */
      height: 100vh; /* Allow height to adjust based on content */
      overflow: visible; /* Allow overflow to be visible */
    }
  
    .bitmoji-image-left {
      position: absolute;
      left: 0; /* Align to the left edge */
      bottom: 10%; /* Align to the bottom with some margin */
      width: 300px; /* Adjust size for mobile */
      height: auto; /* Maintain aspect ratio */
      z-index: 5; /* Ensure image is above other elements */
      animation: fadeInImage 1s ease-out; /* Apply opacity animation if needed */
    }
  
    .small-heading {
      color: var(--gray);
      opacity: 0.3;
      font-family: 'Broman';
      text-decoration: none;
      position: absolute; /* Reset position for mobile */
      font-size: 8rem; /* Adjust font size for mobile */
      transition: color 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center; /* Center heading horizontally */
      z-index: -200; /* Ensure text is above other elements */
      text-align: center; /* Align text to center */
      margin: 0 auto; /* Center text horizontally */
    }
  
    .about-me-text-container {
      font-family: "Inter Bold";
      font-size: 1.4rem; /* Adjust font size for mobile */
      width: 80%; /* Increase width for smaller screens */
      
    }
  
    .about-me-text {
      display: flex; /* Ensure proper spacing and alignment */
      flex-direction: column; /* Stack text vertically */
     
    }
  
    .word {
      display: inline-block;
      opacity: 1; /* Make text visible on mobile */
      transform: translateY(0); /* Reset transform */
    }
  }
  