/* WorksPage.css */

/* General Styling */

/* Animation for wiggle */
@keyframes wiggle {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

.works-container {
  position: relative;
  height: 100vh; /* Full viewport height */
}



.works-page {
  display: flex;
  
  align-items: center;
  justify-content: center;
 
  width: 100vw;
  height: 100vh;
}

.bitmoji-image-right {
  pointer-events: none;
  position: absolute;
  right: 2%; 
  bottom: 0;
  width: 350px;
  height: auto;
  z-index: 5;
  animation: fadeInImage 1s ease-out;
}

.bitmoji-image-left-graphics {
  pointer-events: none;
  position: absolute;
  left: 2%; 
  bottom: 0;
  width: 350px;
  height: auto;
  z-index: 5;
  animation: fadeInImage 1s ease-out;
}

.image-grid-works {
  padding-left: 10%;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: flex-start;
  margin-left: 0;
}



.image-container {
  position: relative;
  display: inline-block;
}

.main-image {
  width: 30rem;
  border-radius: 20px;
  display: block;
  filter: grayscale(100%) contrast(92%) brightness(40%);
  transition: filter 0.3s ease-in-out, scale 0.3s ease-in-out;
}

.main-image:hover {
  scale: 1.03;
}

.peek-image-up,
.peek-image-down {
  position: absolute;
  width: 70px;
  height: 70px;
  transition: top 0.4s ease-in-out;
}

.peek-image-up {
  z-index: -1;
  top: 0;
  left: 0;
}

.peek-image-down {
  z-index: -1;
  top: 20px;
  left: 0;
}

.image-item:hover .peek-image-up {
  top: -74px;
}

.image-item:hover .peek-image-down {
  top: -74px;
}

.image-item:hover .rounded-button {
  scale: 1.15;
}

.image-item:hover .main-image {
  filter: grayscale(0%);
}

.arrow-image {
  padding-left: 5px;
  width: 11px;
}

.image-grid:hover ~ .bitmoji-image-right.show-bitmoji5 {
  content: url('/public/images/bitmoji5.png');
}

.bitmoji-image-right.show-bitmoji5 {
  transition: all 0.2s ease-in-out;
}

.rounded-button-link:hover .arrow-image {
  animation: wiggle 0.6s ease-in-out infinite;
}

.rounded-button-link {
  align-items: center;
  justify-items: center;
  position: absolute;
  text-decoration: none;
  bottom: 20px;
  right: 20px;
}

.rounded-button {
  font-size: 0.8rem;
  font-family: 'Inter Regular';
  background-color: var(--black);
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: scale 0.3s ease-in-out, background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-item:hover .arrow-image {
  animation: wiggle 0.6s ease-in-out infinite;
}

.rounded-button:hover {
  background-color: var(--red);
}

.image-item:hover .main-image {
  scale: 1.03;
}

.image-wrapper {
  width: fit-content;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cloud-text-container {
  position: absolute;
  top: 150px;
  right: 200px;
  z-index: 10;
  width: 300px;
  transition: opacity 1s ease-in-out;
}

.cloud-text-image {
  position: absolute;
  width: 300px;
  right: 30px;
  z-index: 10;
  animation: blink 2s;
}

.close-button {
  position: absolute;
  top: 80px;
  left: -20px;
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 15;
  text-align: center;
  line-height: 1;
}

.cloud-text-container.show {
  opacity: 1;
}

.cloud-text-container.hide {
  pointer-events: none;
  opacity: 0;
}


@media (max-width: 768px) {
  .works-container {
    height: 100vh; /* Adjust to content height */
  }

  .works-page {
    flex-direction: column; /* Stack items vertically */
    width: 100vw;
    height: 100vh; /* Adjust height to fit content */
  }

  .bitmoji-image-right,
  .bitmoji-image-left-graphics {
    width: 200px; /* Smaller image size for mobile */
    bottom: 10px; /* Adjust positioning */
    right: 5%;
   
  }

  .image-grid-works {
   
    gap: 25px; /* Reduce gap for mobile */
    margin-right: 10rem;
    justify-content: center; /* Center items */
  }

  .main-image {
    width: 15rem; /* Smaller image width */
    border-radius: 15px; /* Adjust border-radius */
  }

  .peek-image-up,
  .peek-image-down {
   
    /* transform: rotate(-90deg); */
    width: 40px; /* Smaller peek images */
    height: 40px;
  }

  .peek-image-up {
    top: 0;
  }

  .peek-image-down {
    top: 0px; /* Adjust positioning */
  }

  .image-item:hover .peek-image-up {
    top: -40px;
  }
  
  .image-item:hover .peek-image-down {
    top: -40px;
  }
  

  .arrow-image {
    width: 8px; /* Smaller arrow image */
  }

  .rounded-button {
    font-size: 0.7rem; /* Smaller font size for mobile */
    padding: 8px 16px;
  }

  .cloud-text-container {
    position: absolute;
    top: 24.5rem;
    right: 5.5rem;
    width: 250px;
  }

  .cloud-text-image {
    
    position: absolute;

    top: 0;
    width: 200px; /* Smaller cloud text image */
    bottom: 0; /* Adjust positioning */
    right:  3rem;
  }

  .close-button {
    
   top: 50px;
   left: 0.5rem;
    font-size: 20px; /* Smaller close button font size */
    width: 20px;
    height: 20px;
  }
}